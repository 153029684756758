/* eslint-disable react/prop-types */
// importSource //

import React from "react";
import tw, { css } from "twin.macro";
import { useApp, useAuth, useTheme } from "~hooks";
import { AccountCard, Button, Go, Grid } from "~components";

import { ReactComponent as SME } from "~assets/icons/sme-climate-logo.svg";
import { ReactComponent as Instagram } from "~assets/icons/social-instagram.svg";
import { ReactComponent as LinkedIn } from "~assets/icons/social-linkedin.svg";
import { ReactComponent as Mail } from "~assets/icons/social-mail.svg";
import { ReactComponent as Geneva } from "~assets/icons/social-geneva.svg";

import logo from "~assets/icons/huge-logo.svg";

const SiteNavigation = ({ fill = false, inMenu = false }) => {
  // --------------------------------------------------------------------------
  // context / ref / state

  const { menuActive, setMenuActive, registerActive, setRegisterActive } =
    useApp();
  const { loggedIn } = useAuth();
  const { colours, lowImpactMode } = useTheme();

  // --------------------------------------------------------------------------
  // render

  const animationCSS = `
    animation: 0.5s ease appear-down forwards;

    transform: translate3d(0, -1rem, 0);
    opacity: 0;
  `;
  const initialDelay = 0.2;

  return (
    <section
      css={[
        css`
          height: ${fill ? `100%` : `auto`};
          color: ${lowImpactMode ? colours.background : colours.foreground};
        `,
        tw`relative`
      ]}
    >
      <div
        css={[
          css`
            transition: 0.15s ease background;

            background: ${lowImpactMode
              ? colours.foreground
              : colours.background};
          `,
          tw`w-full h-full absolute top-0 right-0 bottom-0 left-0 z-10`
        ]}
      >
        <img
          css={[tw`w-full h-full relative block object-cover`]}
          src={logo}
          alt="Huge logo"
        />
      </div>

      <div
        css={[tw`w-full h-full relative z-20 pt-12 md:pt-24 pb-12 md:pb-12`]}
      >
        <Grid _css={[tw`h-full relative`]}>
          <div
            css={[
              tw`col-span-12 md:col-span-8 h-full relative flex flex-col items-center justify-between`
            ]}
          >
            {inMenu && !menuActive ? (
              <div />
            ) : (
              <ul css={[tw`w-full relative block`]}>
                <li
                  css={[
                    css`
                      ${animationCSS};
                      animation-delay: ${initialDelay + 0.1}s;
                    `
                  ]}
                >
                  <Go
                    to={`/${loggedIn ? `app/dashboard` : ``}`}
                    onClick={() => setMenuActive(false)}
                  >
                    <h2
                      css={[
                        css`
                          font-size: 6vw;
                        `,
                        tw`text-h1 md:text-h1-md font-head`
                      ]}
                    >
                      Home
                    </h2>
                  </Go>
                </li>

                <li
                  css={[
                    css`
                      ${animationCSS};
                      animation-delay: ${initialDelay + 0.2}s;
                    `
                  ]}
                >
                  <Go to="/about" onClick={() => setMenuActive(false)}>
                    <h2
                      css={[
                        css`
                          font-size: 6vw;
                        `,
                        tw`text-h1 md:text-h1-md font-head`
                      ]}
                    >
                      About
                    </h2>
                  </Go>
                </li>

                <li
                  css={[
                    css`
                      ${animationCSS};
                      animation-delay: ${initialDelay + 0.3}s;
                    `
                  ]}
                >
                  <Go to="/library" onClick={() => setMenuActive(false)}>
                    <h2
                      css={[
                        css`
                          font-size: 6vw;
                        `,
                        tw`text-h1 md:text-h1-md font-head`
                      ]}
                    >
                      Library
                    </h2>
                  </Go>
                </li>

                <li
                  css={[
                    css`
                      ${animationCSS};
                      animation-delay: ${initialDelay + 0.3}s;
                    `
                  ]}
                >
                  <Go
                    to="/expert-directory"
                    onClick={() => setMenuActive(false)}
                  >
                    <h2
                      css={[
                        css`
                          font-size: 6vw;
                        `,
                        tw`text-h1 md:text-h1-md font-head`
                      ]}
                    >
                      Directory
                    </h2>
                  </Go>
                </li>

                <li
                  css={[
                    css`
                      ${animationCSS};
                      animation-delay: ${initialDelay + 0.4}s;
                    `
                  ]}
                >
                  <Go to="/contact" onClick={() => setMenuActive(false)}>
                    <h2
                      css={[
                        css`
                          font-size: 6vw;
                        `,
                        tw`text-h1 md:text-h1-md font-head`
                      ]}
                    >
                      Contact
                    </h2>
                  </Go>
                </li>

                {loggedIn && (
                  <li
                    css={[
                      css`
                        ${animationCSS};
                        animation-delay: ${initialDelay + 0.5}s;
                      `
                    ]}
                  >
                    <Go to="/app/profile" onClick={() => setMenuActive(false)}>
                      <h2
                        css={[
                          css`
                            font-size: 6vw;
                          `,
                          tw`text-h1 md:text-h1-md font-head`
                        ]}
                      >
                        Account
                      </h2>
                    </Go>
                  </li>
                )}
              </ul>
            )}

            <div
              css={[
                css`
                  //
                `,
                tw`w-full relative block`
              ]}
            >
              <ul css={[tw`w-full relative flex mb-6`]}>
                <li>
                  <Go to="/privacy-policy" onClick={() => setMenuActive(false)}>
                    <p css={[tw`mr-6 text-b2 md:text-b2 font-body`]}>Privacy</p>
                  </Go>
                </li>

                <li>
                  <Go
                    to="/terms-of-service"
                    onClick={() => setMenuActive(false)}
                  >
                    <p css={[tw`mr-6 text-b2 md:text-b2 font-body`]}>Terms</p>
                  </Go>
                </li>

                <li>
                  <Go to="/faqs" onClick={() => setMenuActive(false)}>
                    <p css={[tw`mr-6 text-b2 md:text-b2 font-body`]}>FAQs</p>
                  </Go>
                </li>

                <li>
                  <Go to="/user-guides" onClick={() => setMenuActive(false)}>
                    <p css={[tw`mr-6 text-b2 md:text-b2 font-body`]}>
                      User Guides
                    </p>
                  </Go>
                </li>
              </ul>

              <div css={[tw`w-full relative flex items-center md:items-start`]}>
                <p
                  css={[tw`md:w-36 mr-4 md:mr-6 text-b2 md:text-b2 font-body`]}
                >
                  Connect with us
                </p>

                <ul css={[tw`md:w-full relative flex`]}>
                  <li
                    css={[
                      tw`w-8 h-8 relative block mr-2 overflow-hidden rounded-full`
                    ]}
                  >
                    <Go to="https://www.instagram.com/thewip.work/" newTab>
                      <Instagram
                        css={[tw`w-8 h-8 relative`]}
                        fill={
                          lowImpactMode
                            ? colours.background
                            : colours.foreground
                        }
                      />
                    </Go>
                  </li>

                  <li
                    css={[
                      tw`w-8 h-8 relative block mr-2 overflow-hidden rounded-full`
                    ]}
                  >
                    <Go
                      to="https://app.geneva.com/home/9271a402-c9b8-4c79-a363-73ada19ee131/room/c446afed-d353-48e0-9295-59bfac037602"
                      newTab
                    >
                      <Geneva
                        css={[tw`w-8 h-8 relative`]}
                        fill={
                          lowImpactMode
                            ? colours.background
                            : colours.foreground
                        }
                      />
                    </Go>
                  </li>

                  <li
                    css={[
                      tw`w-8 h-8 relative block mr-2 overflow-hidden rounded-full`
                    ]}
                  >
                    <Go to="https://www.linkedin.com/groups/9077249/" newTab>
                      <LinkedIn
                        css={[tw`w-8 h-8 relative`]}
                        fill={
                          lowImpactMode
                            ? colours.background
                            : colours.foreground
                        }
                      />
                    </Go>
                  </li>

                  <li
                    css={[
                      tw`w-8 h-8 relative block mr-2 overflow-hidden rounded-full`
                    ]}
                  >
                    <Go to="mailto:hello@thewip.work">
                      <Mail
                        css={[tw`w-8 h-8 relative`]}
                        fill={
                          lowImpactMode
                            ? colours.background
                            : colours.foreground
                        }
                      />
                    </Go>
                  </li>

                  <li css={[tw`w-16 relative ml-16 hidden md:block`]}>
                    <div
                      css={[
                        css`
                          width: 4rem;
                          top: -2.5rem;
                        `,
                        tw`absolute right-0`
                      ]}
                    >
                      <SME
                        css={[tw`w-full relative block`]}
                        fill={
                          lowImpactMode
                            ? colours.background
                            : colours.foreground
                        }
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div css={[tw`w-full relative md:hidden`]}>
              <div
                css={[tw`w-full relative flex items-center justify-between`]}
              >
                <Go
                  to="/app/login"
                  _css={[
                    css`
                      width: calc(50% - 0.3rem);
                    `,
                    tw`h-10`
                  ]}
                >
                  <Button
                    _css={[
                      css`
                        width: 100%;
                      `,
                      tw`h-10`
                    ]}
                    color={lowImpactMode ? `wheat` : `green-dark`}
                    onClick={() => {
                      setMenuActive(false);
                    }}
                    text="Login"
                  />
                </Go>

                <Button
                  _css={[
                    css`
                      width: calc(50% - 0.3rem);
                    `,
                    tw`h-10`
                  ]}
                  color={lowImpactMode ? `wheat` : `green-dark`}
                  onClick={() => {
                    setMenuActive(false);
                    setRegisterActive(true);
                  }}
                  text="Sign up"
                />
              </div>

              <Button
                _css={[
                  css`
                    width: 100%;
                  `,
                  tw`h-10 mt-3`
                ]}
                color={lowImpactMode ? `wheat` : `green-dark`}
                text="Get in touch"
              />
            </div>
          </div>

          {inMenu && loggedIn && (
            <div
              css={[
                tw`col-span-4 relative hidden md:flex justify-end overflow-visible`
              ]}
            >
              <div
                css={[
                  css`
                    width: 540px;
                    position: relative;
                    flex: 1 0 auto;
                  `
                ]}
              >
                <AccountCard />
              </div>
            </div>
          )}
        </Grid>
      </div>
    </section>
  );
};

export default SiteNavigation;
